import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormInputDate, FormSelectTime, FormRadioGroup, FormInputAddressAutoComplete, FormButton } from '../components/form-elements';
import { dateValidation, requiredValidation } from "../helpers/form-validation-helper";
import { navigate } from "gatsby";
import { useCheckoutActionContext, useCheckoutContext } from "../components/checkout-context";
import useUpdateEffect from "../hooks/use-update-effect";
import CartSummary from "../components/cart-summary";
import usePickupAddress from "../hooks/use-pickup-address";
import { FULFILLMENT } from "../constants";
import { getShippingRate } from "../helpers/shipping-rate-helper";
import CheckoutSteps from "../components/checkout-steps";

export default function CheckoutFulfillment() {
    const { upsertFulfillment } = useCheckoutActionContext();
    const { fulfillment } = useCheckoutContext();

    const [collectionTime, setCollectionTime] = useState(() => fulfillment.collectionTime ? fulfillment.collectionTime : "09:30 AM - 12:30 PM");

    const [collectionDate, setCollectionDate] = useState(() => fulfillment.collectionDate ? fulfillment.collectionDate : "");
    const collectionDateValidations = [(x) => requiredValidation(x, "Date"), dateValidation];

    const [fulfillmentType, setfulfillmentType] = useState(() => fulfillment.fulfillmentType ?? FULFILLMENT.PICKUP);
    const [address, setAddress] = useState(() => fulfillment.address ? fulfillment.address : "");
    const addressValidations = [(x) => requiredValidation(x, "Address")];
    const [isFormSubmitting, setIsFormSubmitting] = useState(() => false);
    const shouldFormButtonDisable = () =>
        !!((collectionDateValidations.find(f => f(collectionDate)))
            || (fulfillmentType != FULFILLMENT.PICKUP && addressValidations.find(f => f(address))));

    const [formSubmitDisabled, setFormSubmitDisabled] = useState(() => shouldFormButtonDisable());

    const form = {
        collectionTime, setCollectionTime,
        collectionDate, setCollectionDate, collectionDateValidations,
        fulfillmentType, setfulfillmentType,
        address, setAddress, addressValidations,
        formSubmitDisabled, isFormSubmitting
    }

    const formSubmit = (e) => {
        e.preventDefault();
        const upsertFulfillmentWithShipping = async () => {
            setIsFormSubmitting(true);

            upsertFulfillment({
                collectionTime
                , collectionDate
                , fulfillmentType
                , address: (fulfillmentType === FULFILLMENT.DELIVERY) ? address : ''
                , shippingRate: (fulfillmentType === FULFILLMENT.DELIVERY) ? await getShippingRate(address) : {}
            })
        }
        upsertFulfillmentWithShipping()
            .then(() => navigate('/checkout-notes/'))
            .catch(() => { setIsFormSubmitting(true); console.error("Unable to dispatch fulfillment."); });
    };

    useUpdateEffect(
        () => {
            setFormSubmitDisabled(shouldFormButtonDisable());
        },
        [collectionDate, address, fulfillmentType]
    );

    return (
        <>
            <Seo title="Checkout Fulfillment" />
            <BrandHeading title="checkout" />
            <section>
                <CartSummary />
                <CheckoutSteps step="fulfillment" />
                <Form form={form} onSubmit={formSubmit} />
            </section>
        </>
    )
}

function Form({ form, onSubmit }) {

    return (
        <div className="grid grid-cols-1 gap-6 max-w-2xl mx-auto">
            <FormRadioGroup
                label={""}
                value={form.fulfillmentType}
                onChange={form.setfulfillmentType}
                groupName={"fulfillment"}
                inline={true}
                options={[
                    { value: FULFILLMENT.PICKUP, labels: ["PICK-UP"] },
                    { value: FULFILLMENT.DELIVERY, labels: ["DELIVERY"] },
                ]}
            />

            <div className="flex justify-between">
                <div className="w-full pr-4">
                    <FormInputDate label={form.fulfillmentType == FULFILLMENT.PICKUP ? "PICK-UP DATE *" : "DELIVERY DATE *"}
                        value={form.collectionDate}
                        onChange={form.setCollectionDate}
                        validations={form.collectionDateValidations}
                    />
                </div>
                <div className="w-full"> <FormSelectTime
                    label={form.fulfillmentType == FULFILLMENT.PICKUP ? "PICK-UP TIME" : "DELIVERY TIME"}
                    value={form.collectionTime} onChange={form.setCollectionTime}
                />
                </div>
            </div>

            {
                (form.fulfillmentType == FULFILLMENT.PICKUP) ?
                    <label className="block">
                        <span className="font-ovo text-base tracking-wider text-gray-800">{"PICK-UP ADDRESS"}</span>
                        <p className="font-gotu text-xs md:text-[.80rem] tracking-wider text-pas-gray">{usePickupAddress()}</p>
                    </label>
                    :
                    <FormInputAddressAutoComplete
                        label={"DELIVERY ADDRESS *"}
                        value={form.address}
                        onChange={form.setAddress}
                        validations={form.addressValidations} />

            }
            <hr />
            <div className="w-full max-w-xs mx-auto my-8">
                <FormButton
                    disabled={form.formSubmitDisabled || form.isFormSubmitting}
                    className={"w-full mb-4"}
                    label={"CONTINUE"}
                    onClick={e => { onSubmit(e) }}
                    isProcessing={form.isFormSubmitting} />
                <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-customer/`)} isSecondary={true} />
                <FormButton className="w-full mb-4" label={"RETURN TO CART"} onClick={() => navigate(`/cart/`)} isSecondary={true} />
                <p className="font-gotu italic text-center text-[0.65rem] tracking-wider text-red-500">
                    {form.formSubmitDisabled ? `Please fill out the required (*) fields to enable the SUBMIT button` : ""}
                </p>
            </div>
        </div>
    )
}